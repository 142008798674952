<template>
  <client-only>
    <Swiper :options="options" class="custom-slider position-relative z-index-2">
      <div v-for="productItem of carouselList" :key="productItem.id" class="swiper-slide">
        <card-product
          :product="productItem"
          :isSlider="true"
          @changeSlider="changeSlider"
        />
      </div>
    </Swiper>
  </client-only>
</template>

<script>
export default {
  name: 'AppSlickCarousel',
  components: {
    CardProduct: () => import('~/components/cards/card-product.vue'),
    Swiper: () => import('~/components/Swiper.vue')
  },
  props: {
    carouselList: {
      type: Array,
      required: true
    },
    sliderId: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data () {
    return {
      addClass: false,
      options: {
        slidesPerView: 2,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          767: {
            slidesPerView: 4
          }
        }
      }
    }
  },
  computed: {
    perPage () {
      return this.isMobile ? 2 : 5
    }
  },
  methods: {
    changeSlider () {
      if (this.sliderId) {
        this.$cookies.set('productId', this.sliderId)
      }
    }
  }
}
</script>

<style scoped>
.custom-slider >>> .swiper-button-next{
  right: 0px;
}
.custom-slider >>> .swiper-button-prev {
  left: 0px;
}
.custom-slider >>> :is(.swiper-button-next, .swiper-button-prev) {
  background-color: rgba(0, 0, 0, .149) !important;
  border-radius: 50%;
}
.custom-slider >>> :is(.swiper-button-next, .swiper-button-prev):after {
  color: #fff;
}
.custom-slider >>> .swiper-wrapper {
  margin: 20px auto;
}
@media all and (min-width: 766.9px) {
  .custom-slider >>> .swiper-wrapper {
    margin: 27px auto 32px;
  }
}
.custom-slider >>> :is(.swiper-button-next, .swiper-button-prev):after {
  font-size: 15px;
}
.custom-slider >>> :is(.swiper-button-next, .swiper-button-prev) {
  height: 30px;
  width: 30px;
  top: 40%;
}
</style>
